const AccountIcon = {
	getAccountIcon() {
		return (
			<svg
				id='Group_5796'
				data-name='Group 5796'
				xmlns='http://www.w3.org/2000/svg'
				xmlnsXlink='http://www.w3.org/1999/xlink'
				width='26'
				height='26'
				viewBox='0 0 34 34'
			>
				<defs>
					<clipPath id='clip-path_profile'>
						<rect
							id='Rectangle_7121'
							data-name='Rectangle 7121'
							width='32.331'
							height='32.331'
							fill='#bfbfbf'
						/>
					</clipPath>
				</defs>
				<g
					id='Group_5795'
					data-name='Group 5795'
					clipPath='url(#clip-path_profile)'
				>
					<path
						id='Path_120521'
						data-name='Path 120521'
						d='M16.166,32.331A16.17,16.17,0,0,1,9.873,1.271,16.169,16.169,0,0,1,22.458,31.06a16.064,16.064,0,0,1-6.293,1.271m0-30.437A14.271,14.271,0,1,0,30.437,16.166,14.287,14.287,0,0,0,16.166,1.894'
						fill='#bfbfbf'
					/>
					<path
						id='Path_120522'
						data-name='Path 120522'
						d='M199.834,164.026a7.034,7.034,0,1,1,7.035-7.035,7.043,7.043,0,0,1-7.035,7.035m0-12.175a5.14,5.14,0,1,0,5.14,5.14,5.146,5.146,0,0,0-5.14-5.14'
						transform='translate(-183.668 -142.855)'
						fill='#bfbfbf'
					/>
					<path
						id='Path_120523'
						data-name='Path 120523'
						d='M148.722,417.072a.947.947,0,0,1-.941-.847,8.185,8.185,0,0,0-16.275,0,.947.947,0,1,1-1.884-.2,10.079,10.079,0,0,1,20.043,0,.947.947,0,0,1-.842,1.042.969.969,0,0,1-.1.005'
						transform='translate(-123.478 -387.746)'
						fill='#bfbfbf'
					/>
				</g>
			</svg>
		);
	},
};
export default AccountIcon;
