const LoginEndPoints = {
  login(data: object) {
    return {
      url: "governance/login",
      data: data,
    };
  },
  forgotPassword(data: object) {
    return {
      url: "governance/forget_password",
      data,
    };
  },
  getGlobalSettings(data: any) {
    return {
      url: "globalreport/fetch_global_data",
      data: data.reqBody,
      headers: data.headers,
    };
  },
  getLoginDetails(data: any) {
    return {
      url: "governance/oauth",
      headers: data.headers,
      data: {},
    };
  },
  keyCloakAuth(data: any) {
    return {
      url: "kcauth/verify",
      data: data.headers,
    };
  },
  regenrateKeyCloakToken(data: any) {
    return {
      url: "kcauth/refreshToken",
      headers: data,
      data: {},
    };
  },
  validateAccessToken(data: any) {
    return {
      url: "kcauth/validate",
      headers: data.headers,
      data: {},
    };
  },
  logoutUser(data: object) {
    return {
      url: "kcauth/logout",
      headers: {},
      data: data,
    };
  },
};

export default LoginEndPoints;
