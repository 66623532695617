import { createContext, useReducer, useContext } from 'react';
import useLocalStorage from '../utils/localStorage';

const AppContext:any = createContext([]);

const localStorage = useLocalStorage;

export enum iTheme {
	'LIGHT' = 'light',
	'DARK' = 'dark',
}

const initialState = {
	isAuthenticated: !!localStorage.getItem('userData'),
	userData: localStorage.getItem('userData') || {},
	onLoaded: { showLoader: false },
	showSidebar: true,
	onSidebarToggle: { sidebarToggle: false },
	editProfile: localStorage.getItem('userData')?.name || '',
	segmentsList: [],
	// insightFilters: InsightsData.getDefaultFilters(),
	connected_crm: [],
	theme: iTheme.DARK,
	isQuestionsVisible: false,
	isAnswersVisible: false,
	insightsQuestions: [],
};

function AppContextProvider({ reducer, children }: any) {
	return (
		<AppContext.Provider value={useReducer(reducer, initialState)}>
			{children}
		</AppContext.Provider>
	);
}

const useAppContext = () => useContext(AppContext);

export { AppContext, AppContextProvider, useAppContext };
