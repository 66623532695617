import useLocalStorage from '../../utils/localStorage';
import * as actionTypes from '../actions/actionTypes';

const localStorage = useLocalStorage;

const onLoginResponse = async (state: any, action: any) => ({
	...state,
	isAuthenticated: true,
	loginData: { ...action.data },
});

const onLogout = (state: any, action: any) => {
	const embeded = localStorage.getItem('embeded') || false;
	const appTheme = localStorage.getItem('appTheme') || 'light';
	localStorage.clear();
	localStorage.setItem('embeded', embeded);
	localStorage.setItem('appTheme', appTheme);
	return {
		...state,
		isAuthenticated: false,
		loginData: {},
	};
};

const onLoaded = (state: any, action: any) => ({
	...state,
	onLoaded: { ...action.data },
});

const onSidebarToggle = (state: any, action: any) => ({
	...state,
	onSidebarToggle: action.data,
});

const toggleSidebar = (state: any, action: any) => ({
	...state,
	showSidebar: action.data,
});

export const onSegmentListUpdate = (state: any, action: any) => ({
	...state,
	segmentsList: action.data,
});
export const onEditProfile = (state: any, action: any) => ({
	...state,
	editProfile: action.data,
});

export const onInsightFilterChange = (state: any, action: any) => ({
	...state,
	insightFilters: action.data,
});

export const onConnectorChange = (state: any, action: any) => ({
	...state,
	connected_crm: action.data,
});

export const onToggleTheme = (state: any, action: any) => {
	const appTheme = localStorage.getItem('appTheme') || 'light';
	localStorage.setItem('appTheme', appTheme);
	return {
		...state,
		theme: action.data,
	};
};
export const questionsDrawerHandler = (state: any, action: any) => ({
	...state,
	isQuestionsVisible: action.data,
});

export const answerDrawerHandler = (state: any, action: any) => ({
	...state,
	isAnswersVisible: action.data,
});

export const setInsightsQuestionsHandler = (state: any, action: any) => ({
	...state,
	insightsQuestions: action.data,
});

const app = (state: any, action: any) => {
	switch (action.type) {
		case actionTypes.LOGIN_RESPONSE:
			return onLoginResponse(state, action);
		case actionTypes.LOGOUT:
			return onLogout(state, action);
		case actionTypes.LOADER:
			return onLoaded(state, action);
		case actionTypes.SIDEBAR:
			return onSidebarToggle(state, action);
		case actionTypes.EDITPROFILE:
			return onEditProfile(state, action);
		case actionTypes.TOGGLESIDEBAR:
			return toggleSidebar(state, action);
		case actionTypes.SEGMENT_LIST_UPDATE:
			return onSegmentListUpdate(state, action);
		case actionTypes.INSIGHT_FILTER_CHANGE:
			return onInsightFilterChange(state, action);
		case actionTypes.CONNECTOR_CHANGE:
			return onConnectorChange(state, action);
		case actionTypes.TOGGLETHEME:
			return onToggleTheme(state, action);
		case actionTypes.INSIGHTS_DRAWER:
			return questionsDrawerHandler(state, action);
		case actionTypes.ANSWER_DRAWER:
			return answerDrawerHandler(state, action);
		case actionTypes.INSIGHTS_QUESTIONS:
			return setInsightsQuestionsHandler(state, action);
		default:
			return state;
	}
};

export default app;
