import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ToastContextProvider } from './Components/Toast/Toast.context';
import toastReducer from './Components/Toast/reducers/reducer';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from './AppContext/App.context';
import appReducer from './AppContext/reducers/reducer';

ReactDOM.render(
	<Suspense fallback={''}>
		<BrowserRouter>
			<AppContextProvider reducer={appReducer}>
				<ToastContextProvider reducer={toastReducer}>
					
					<App />
				</ToastContextProvider>
			</AppContextProvider>
		</BrowserRouter>
	</Suspense>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
