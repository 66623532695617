import React, { lazy, useEffect, useMemo, useState } from "react";
import "./App.scss";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import Toast from "./Components/Toast/Toast";
import PageLoader from "./Components/pageLoader/pageLoader";
import HeaderSidebarWrapper from "./Components/Sidebar/HeaderSidebarWrapper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { themeSettings } from "./appTheme/theme";
import useLoader from "./hooks/useLoader";
import useApiService from "./services/api.service";
import useLocalStorage from "./utils/localStorage";
import LoginEndPoints from "./Containers/Login/Login.endpoints";
import useToast from "./Components/Toast/hooks/useToast";
import { Grid } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const lazyWithRetry = (componentImport: any, name: any) => async () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
  );

  try {
    const component = await componentImport();

    window.sessionStorage.setItem(`retry-${name}-refreshed`, "false");

    return component;
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      // Assuming that the user is not on the latest version of the application.
      // Let's refresh the page immediately.
      window.sessionStorage.setItem(`retry-${name}-refreshed`, "true");
      return window.location.reload();
    }

    // The page has already been reloaded
    // Assuming that user is already using the latest version of the application.
    // Let's let the application crash and raise the error.
    throw error;
  }
};

const Login = lazy(
  lazyWithRetry(() => import("../src/Containers/Login/Login"), "login")
);

const EmailListing = lazy(
  lazyWithRetry(
    () => import("../src/Containers/EmailListing/EmailListing"),
    "email-list"
  )
);
const DetailedView = lazy(
  lazyWithRetry(
    () => import("../src/Containers/DetailedView/DetailedView"),
    "email-list"
  )
);
const SendEmail = lazy(
  lazyWithRetry(
    () => import("../src/Containers/SendEmail/SendEmail"),
    "send-email"
  )
);
const exclusionArray = [
  "/",
  "/login",
  "/signin",
  // '/change-password',
];
function App() {
  const location = useLocation();
  const isHeaderSidemenuVisible = exclusionArray.indexOf(location.pathname) < 0;
  const theme: any = useMemo(
    () => createTheme(themeSettings("light")),
    ["light"]
  );
  const [isLoading, setIsLoading]: any = useState(true);
  const loader = useLoader();
  const toaster: any = useToast();

  const APIService = useApiService();

  const validateAccessTokenWithKeyCloakServer = (accessToken: any) => {
    const headers: any = {
      "access-token": accessToken,
    };
    const reqData: any = {
      request: {},
      headers: headers,
    };

    loader.showLoader();
    return APIService.get(LoginEndPoints.validateAccessToken(reqData))
      .then(async (response: any) => {
        return response;
      })
      .catch((err: any) => {
		setIsLoading(false);
        window.location.href = "/";
        toaster.addToast({
          message: err.message || "Something went wrong",
          timeout: 2000,
          type: "error",
        });
        loader.hideLoader();
        return false;
      });
  };

  const getKeyCloakAccessToken = (keyCloakCode: any) => {
    const headers: any = {
      code: keyCloakCode,
      redirect_uri: window.location.origin + "/login",
    };
    const reqData: any = {
      request: {},
      headers: headers,
    };

    loader.showLoader();
    return APIService.get(LoginEndPoints.keyCloakAuth(reqData))
      .then(async (response: any) => {
        if (response?.data["access_token"]) {
          let validateReponse = await validateAccessTokenWithKeyCloakServer(
            response?.data["access_token"]
          );

          if (validateReponse.status == 200) {
            await getLoginDetailsFromToken(
              response?.data["access_token"],
              response?.data["refresh_token"],
              validateReponse?.data["user_type"]
            );
          }
        }
        loader.hideLoader();
      })
      .catch((err: any) => {
		setIsLoading(false);
        window.location.href = "/";
        // window.location.pathname = "/login";
        toaster.addToast({
          message: err.message || "Something went wrong",
          timeout: 2000,
          type: "error",
        });
        loader.hideLoader();
      });
  };

  const getLoginDetailsFromToken = (
    acessTokenFromKeyCloak?: any,
    keyCloakRefreshToken?: any,
    userType?: any,
    token?: any
  ) => {
    const headers: any = {
      "access-token": acessTokenFromKeyCloak,
    };
    const reqData: any = {
      request: {},
      headers: headers,
    };

    // loader.showLoader();
    return APIService.get(LoginEndPoints.getLoginDetails(reqData))
      .then(async (response: any) => {
        // debugger;
        const res = response.data;
        delete res?.custom_nav_bar;
        res["access-token"] = acessTokenFromKeyCloak;
        res["refresh-token"] = keyCloakRefreshToken;
        useLocalStorage.setItem("userData", res);
        window.location.href = "/email-listing";
        setIsLoading(false);
        loader.hideLoader();
      })
      .catch((err: any) => {
        toaster.addToast({
          message: err.message || "Something went wrong",
          timeout: 2000,
          type: "error",
        });
        setIsLoading(false);
        loader.hideLoader();
      });
  };

  useEffect(() => {
    const callFunction = async () => {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const keyCloakCode = params.get("code");
      if (keyCloakCode) {
        setIsLoading(true);
        // get keycloak authetication according to key cloak code
        await getKeyCloakAccessToken(keyCloakCode);
      }else{
		setIsLoading(false);
	  }
    };
    callFunction();
  }, []);

  return (
    <ThemeProvider
      // theme={getTheme()}
      theme={theme}
    >
      {!isLoading ? (
        <div
          className={isHeaderSidemenuVisible ? "app" : ""}
          style={{ backgroundColor: "" }}
        >
          {isHeaderSidemenuVisible ? <HeaderSidebarWrapper /> : null}
          <div
            className={
              isHeaderSidemenuVisible ? "app__outer" : "app__outer app__embeded"
            }
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/email-listing" element={<EmailListing />} />
              <Route path="/detailed-view" element={<DetailedView />} />

              <Route path="" element={<Navigate to="/login" />} />
              <Route path="/send-email" element={<SendEmail />} />
            </Routes>
            {/* <PageLoader/> */}
            <Toast />
            {/* <Login/> */}
          </div>
        </div>
      ) : (
        <>
          <Grid
            item
            style={{
              width: "100%",
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              size={50}
              style={{ color: "#9149FF", zIndex: 9999 }}
            />{" "}
          </Grid>
        </>
      )}
    </ThemeProvider>
  );
}

export default App;
