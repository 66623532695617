import useToast from "../Toast/hooks/useToast";
import AccountIcon from "./SidebarIcons/AccountIcon";
import SidebarIcons from "./SidebarIcons/SidebarIcons";

import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import * as actions from "../../AppContext/actions/actions";
import { useAppContext } from "../../AppContext/App.context";

import DiggLogo from "../../assets/DigGrowthLogo.svg";
import DiggLogoDark from "../../assets/DiggLogoDark.svg";
import { useClickOutside } from "../../hooks/clickOutside";
import useLoader from "../../hooks/useLoader";
import useApiService from "../../services/api.service";
import CommonEndpoints from "../../services/commonAPI.Service";
import useLocalStorage from "../../utils/localStorage";

import "./HeaderSidebarWrapper.scss";
import LoginEndPoints from "../../Containers/Login/Login.endpoints";

// globals
const drawerWidth = 224;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: any) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function HeaderSidebarWrapper() {
  const navigate = useNavigate();
  const [appData, dispatch]: any = useAppContext();
  const { userData, showSidebar, connected_crm } = appData;
  const isSystemEmbeded = useLocalStorage.getItem("embeded") || false;
  const reportName: any =
    useLocalStorage.getItem("customReportName") || "Custom Reporting";
  const isOptionSelected = (path: any, type?: any) =>
    type !== 0 && window.location.pathname.includes(path);

  // refs and others
  const sidebarRef: any = useRef();
  const loader = useLoader();
  const APIService = useApiService();
  const toaster = useToast();
  const embeded = useLocalStorage.getItem("embeded") ? "54px" : "0px";
  const validation = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  // Build Live

  function SidebarItem(data: any) {
    return (
      <>
        {data.hidden !== true ? (
          <ListItem
            onMouseEnter={data.onMouseEnter}
            // onMouseLeave={data.onMouseLeave}
            style={data.heading ? { pointerEvents: "none" } : {}}
            className={
              !data.isSubCategory || data.isListItem
                ? "Sidebar__item"
                : "Sidebar__subItem"
            }
            onClick={
              !data.onClick
                ? () => {
                    if (data.navigatePath) {
                      // hidePopOverEffect();
                      navigate(data.navigatePath);
                      // sidebarToggle.sidebarToggle(false);
                    }
                  }
                : data.onClick
            }
            sx={{
              // minWidth: 0,
              mr: showSidebar ? 3 : "auto",
              justifyContent: "center",
            }}
            key={data.title}
            button
            selected={isOptionSelected(data.path)}
            disabled={data.disabled}
          >
            {data.showBorderSelector && isOptionSelected(data.path) ? (
              <div className="Sidebar__lineSelector" />
            ) : (
              <></>
            )}
            <ListItemIcon
              className={
                isOptionSelected(data.path)
                  ? "Sidebar__item__svg__selected sidebar_svg"
                  : "sidebar_svg"
              }
              sx={{
                // minWidth: 0,
                mr: showSidebar ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <span>{data.icon}</span>
            </ListItemIcon>

            <ListItemText
              sx={{
                opacity: showSidebar
                  ? 1
                  : data.heading || data.isListItem
                  ? 1
                  : !data.isSubCategory
                  ? 0
                  : 1,
              }}
              style={{
                color: `${isOptionSelected(data.path) ? "#9149ff" : "black"}`,
                fontWeight: `normal`,
              }}
              className={
                !data.isSubCategory || data.isListItem
                  ? "Sidebar__item__text"
                  : "Sidebar__item__SubText"
              }
              primary={
                <div
                  className={
                    data?.path == "/editProfile"
                      ? "Sidebar__item__textOverflow"
                      : ""
                  }
                >
                  {data.title}
                </div>
              }
              primaryTypographyProps={{ component: "div" }}
            />

            {/* {data.stateChange !== undefined ? (
							data.stateChange ? (
								<ChevronRightIcon color='primary' />
							) : (
								<ExpandMore style={{ color: '#BFBFBF' }} />
							)
						) : null} */}
          </ListItem>
        ) : null}
      </>
    );
  }

  const logoutSessionFromKeyCloak = async () => {
    loader.showLoader();
    let request = {
      refresh_token: userData["refresh-token"],
    };
    return await APIService.get(LoginEndPoints.logoutUser(request))
      .then((res: any) => {
        loader.hideLoader();
        return true;
      })
      .catch((err: any) => {
        loader.hideLoader();
        toaster.addToast({
          message: err.message || "Something went wrong",
          timeout: 2000,
          type: "error",
        });
        return false;
      });
  };

  // Keep this if conflict arrised
  const onLogOut = async () => {
    let isLogout = await logoutSessionFromKeyCloak();
    if (isLogout) {
      window.location.href = "/";
      localStorage.clear();
    }
  };

  const decideCSSForSidebarList = (classNameFor: string) => {
    switch (classNameFor) {
      case "sidebar":
        if (showSidebar) {
          return `Sidebar__list`;
        }
        if (!showSidebar) {
          return `Sidebar__listClosed`;
        }
        break;
      case "sidebarSubCategory":
        if (showSidebar) {
          return `Sidebar__subCategoryList`;
        }
        if (!showSidebar) {
          return `Sidebar__subCategoryListClosed`;
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="Sidebar" ref={sidebarRef}>
        <AppBar
          position="fixed"
          open={showSidebar}
          className={showSidebar ? "Sidebar__appbar" : "Sidebar__appbarClosed"}
        ></AppBar>
        <Drawer
          sx={{
            flexShrink: 0,
            // '& .MuiPaper-root': {
            // 	backgroundColor: theme.palette.background.alt,
            // 	borderRight: theme.palette.mode === 'light' ? 'none' : '',
            // },
          }}
          className="Sidebar__drawer"
          variant="persistent"
          anchor="left"
          // open={true}
          open={showSidebar}
        >
          <DrawerHeader>
            <div
              className={
                showSidebar
                  ? "Sidebar__logoContainer"
                  : "Sidebar__logoContainer Sidebar__logoContainerClosed"
              }
              // style={{ height: '88px' }}
            >
              {showSidebar ? (
                <img
                  src={DiggLogo}
                  alt="Company Logo"
                  style={{ height: "55px", width: "90%" }}
                />
              ) : (
                <img
                  // src={DiggLogoSM}
                  alt="digg logo"
                  style={
                    embeded === "0px"
                      ? { height: "65px", width: "65%" }
                      : { height: "55px", width: "70%" }
                  }
                />
              )}
            </div>
          </DrawerHeader>
          <div
            className="Sidebar__divider"
            style={{ backgroundColor: "#BEBEBE" }}
          />
          <List
            className={
              showSidebar ? "Sidebar__list icon" : "Sidebar__listClosed icon"
            }
          >
            <div
              className="Sidebar__divider"
              style={{ backgroundColor: "#BEBEBE" }}
            />
            <SidebarItem
              title={"Logout"}
              path="/editProfile"
              icon={AccountIcon.getAccountIcon()}
              // navigatePath='/editProfile'
              // icon={AccountIcon.getAccountIcon()}
              onClick={() => {
                onLogOut();
              }}
              // stateChange={}
              showBorderSelector
              hidden={isSystemEmbeded}
            />
          </List>
          <List
            // className={showSidebar ? 'Sidebar__list' : 'Sidebar__listClosed'}
            className={decideCSSForSidebarList("sidebar")}
          >
            {/* Insights with collapse */}
            <SidebarItem
              title="Campaign Listing"
              path="/email-listing"
              icon={SidebarIcons.getDashboardIcon()}
              onClick={() => {
                window.location.href = "/email-listing";
                // sidebarToggle.sidebarToggle(!insightsListOpen);
              }}
              // stateChange={insightsListOpen}
              showBorderSelector
            />
            <div
              className="Sidebar__divider"
              style={{ backgroundColor: "#BEBEBE" }}
            />
          </List>
        </Drawer>
      </div>
    </>
  );
}

export default HeaderSidebarWrapper;
