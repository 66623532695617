import axios from 'axios';
import { useAppContext } from '../AppContext/App.context';
// import useToast from "../components/Toast/hooks/useToast";

/**
ogi */

const useAxiosInterceptor = () => {
	// const toaster = useToast();
	const userDataJson: any = localStorage.getItem('userData');
	const [appData, dispatch]: any = useAppContext();
	const userData: any = userDataJson ? JSON.parse(userDataJson) : null;
	const instance = axios.create({
		validateStatus: function (status: any) {
			return status >= 200;
		},
	});
	// Add a response interceptor
	instance.interceptors.request.use(function (response: any) {
		response.validateStatus = function (status: any) {
			return status >= 200;
		};
		return response;
	});
	instance.interceptors.response.use(
		async (response: any) => {
			if (
				response.status &&
				response.status !== 200 &&
				response.status !== 201 &&
				response.status !== 202
			) {
				if (response.status === 440) {
					if (userData) {
						let refreshToken = userData['refresh-token'];
						let apiUrl =
							process.env.REACT_APP_API_URL +
							`kcauth/refreshToken?refresh_token=${refreshToken}`;
						try {
							const response = await fetch(apiUrl, {
								method: 'GET',
							});

							if (response.status === 440) {
								window.location.href = '/login';
								localStorage.removeItem('userData');
								localStorage.removeItem('isAuthenticated');
								localStorage.removeItem('abmConfiguration');
								localStorage.removeItem('isInsightsOpened');
							} else {
								const responseData = await response.json();
								if (userData) {
									userData['refresh-token'] = responseData.data.refresh_token;
									userData['access-token'] = responseData.data.access_token;
									localStorage.setItem('userData', JSON.stringify(userData));
								}
								// window.location.reload();
							}
						} catch (error) {
							// Handle any fetch errors here
							console.error('Fetch error:', error);
						}
					}
					return Promise.reject(response.data);
				}
				if (response.status == 500 || response.status == 404) {
					return Promise.reject(response.data);
				} else {
					return Promise.reject(response.data);
				}
			}
			return response.data;
		},
		(error: any) => {
			return Promise.reject(error);
		}
	);
	return instance;
};

export default useAxiosInterceptor;
